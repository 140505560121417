import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SeO from "../components/seo"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = (props) => (
    <StaticQuery
        query={graphql`
          query GetAllFaqRows {
            allFaqJson {
              edges {
                node {
                  question
                  answer
                }
              }
            }
          } 
        `}
        render={data=>(
            <React.Fragment>
                <SeO title="Συχνές Ερωτήσεις" description="Συχνές Ερωτήσεις" />
                    <Accordion>
                        {
                            data.allFaqJson.edges.map((item, index) => (
                                <AccordionItem key={index}>
    <                               AccordionItemHeading>
                                        <AccordionItemButton>
                                            {++index}. {item.node.question}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            {item.node.answer}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))
                        }
                </Accordion>
            </React.Fragment>
          
        )}
  />
  
)

export default Faq